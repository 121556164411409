// State
export const state = () => ({
	components: [],
	component: {},
	componentContents: [],
	selectedCurrencyLanguages: [],
	hasDefault: false,
	createdForm: {},
	loading: false,
	menubar: [],
	menubarIcons: [],
	// cancel token
	cmsCancelToken: {},
	themes: [],
	componentsListObj: {}
})

// Actions
export const actions = {
	async components({ commit, state }, params) {
		// Check if the data exists, don't fire API again
		const componentListObjBaseOnThemeCode = state.componentsListObj[params?.theme_code]
		if (componentListObjBaseOnThemeCode) {
			commit('components', componentListObjBaseOnThemeCode)
			return componentListObjBaseOnThemeCode
		}
		commit('setCMSCancelToken', { components: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/components`, { params, cancelToken: state.cmsCancelToken.components.token })
			.then((response) => {
				commit('components', response?.data)
				// Set components data into temporary data
				commit('setComponentsListObj', {
					[params.theme_code]:
						response?.data
				})
			})
			.catch(() => false)
	},
	async componentContents({ commit, state }, params) {
		commit('setCMSCancelToken', { componentContents: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/component-contents`, { params, cancelToken: state.cmsCancelToken.componentContents.token })
			.then((response) => {
				commit('setComponentContent', response.data.components)
			})
			.catch(() => false)
	},
	async menubarGroup({ commit, state }, params) {
		commit('setCMSCancelToken', { menubarGroup: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/component-contents/menubar`, { params, cancelToken: state.cmsCancelToken.menubarGroup.token })
			.then((response) => {
				commit('setMenubar', response.data.components)
			})
			.catch(() => false)
	},
	async componentContent({ commit, state }, id) {
		commit('setCMSCancelToken', { componentContent: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/component-contents/${id}`, { cancelToken: state.cmsCancelToken.componentContent.token })
			.then((response) => {
				const responseData = response.data
				if (!responseData._id) {
					responseData._id = id
				}
				commit('setComponent', responseData)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},

	async createComponent({ commit, rootGetters }, data) {
		commit('setLoading', true)
		await this.$axios
			.post(`${this.$config.LANDING_API}/cms/bo/component-contents`, data)
			.then((response) => {
				if (typeof response.data === 'string') {
					this.$toaster({ title: rootGetters['master/storeTl'](response.data) })
				} else {
					this.$toaster({ title: response.data })
				}

				commit('setLoading', false)
				this.$router.push('/frontend/cms')
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async updateComponent({ commit, rootGetters }, data) {
		const { form, noReturn } = { ...data }
		const id = data.id
		commit('setLoading', true)
		await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/${id}`, form)
			.then((response) => {
				if (typeof response.data === 'string') {
					this.$toaster({ title: rootGetters['master/storeTl'](response.data) })
				} else {
					this.$toaster({ title: rootGetters['master/storeTl'](response.data.message) })
				}
				commit('setLoading', false)
				if (noReturn !== true) {
					// this.$router.push('/frontend/cms')
					this.$router.go('-1')
				}
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async updateCombinedComponent({ commit, rootGetters }, data) {
		const { form, noReturn } = { ...data }
		commit('setLoading', true)
		await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/combined`, form)
			.then((response) => {
				if (typeof response.data === 'string') {
					this.$toaster({ title: rootGetters['master/storeTl'](response.data) })
				} else {
					this.$toaster({ title: rootGetters['master/storeTl'](response.data.message) })
				}
				commit('setLoading', false)
				if (noReturn !== true) {
					this.$router.go('-1')
				}
			})
			.catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async deleteComponents({ commit, dispatch, state }, id) {
		const response = await this.$axios
			.delete(`${this.$config.LANDING_API}/cms/bo/component-contents/items/${id}`)
			.then((response) => {
				dispatch('componentContent', state.component._id)
				return true
			})
			.catch(() => {
				return false
			})

		return response
	},
	async deleteComponent({ commit, dispatch }, data) {
		const { id, currency } = data
		const response = await this.$axios
			.delete(`${this.$config.LANDING_API}/cms/bo/component-contents/${id}`)
			.then((response) => {
				dispatch('componentContents', { currency })
				return true

			})
			.catch(() => { return false })
		return response
	},
	async visibleComponent({ commit }, body) {
		const response = await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/hide-component-content/${body.id}`, body.data)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async visibleComponentItem({ commit }, body) {
		const response = await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/hide-component-content-item/${body.id}`, { is_visible: body.is_visible })
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async menubarIcons({ commit }) {
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/component-contents/menubar-icons`)
			.then((response) => {
				commit('setMenubarIcons', response.data)

			})
			.catch(() => false)
	},
	async updateItemsSequence({ commit }, body) {
		const response = await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/items/sequence/${body.id}`, body.body)
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	},
	async updateMenuSequence({ commit }, data) {
		const response = await this.$axios
			.put(`${this.$config.LANDING_API}/cms/bo/component-contents/sequence/${data.currency}`, data.body, { params: data.params })
			.then((response) => {
				return true
			})
			.catch(() => false)
		return response
	},
	async uploadImage({ commit }, body) {
		const response = await this.$axios
			.post(`${this.$config.LANDING_API}/cms/bo/component-contents/image/upload/${body.id}`, body.data)
			.then((response) => {
				return response.data
			})
			.catch(() => false)
		return response
	},
	async themes({ commit, state }, params) {
		commit('setCMSCancelToken', { themes: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`${this.$config.LANDING_API}/cms/bo/themes`, { params, cancelToken: state.cmsCancelToken.themes.token })
			.then((response) => {
				commit('themes', response?.data)
			})
			.catch(() => false)
	},
}

// Mutations
export const mutations = {
	components(state, response) {
		state.components = response
	},
	setSelectedCurrencyLanguages(state, data) {
		const cloneData = JSON.parse(JSON.stringify(data))
		state.selectedCurrencyLanguages = cloneData.sort((a, b) => {
			if (a.name === 'English') {
				return 1; // 'English' should be at the end
			} else if (b.name === 'English') {
				return -1; // 'English' should be at the end
			} else {
				return a.name.localeCompare(b.name);
			}
		})
	},
	setDefault(state, data) {
		state.hasDefault = data
	},
	setCreatedForm(state, data) {
		state.createdForm = data
	},
	setComponentContent(state, data) {
		state.componentContents = data
	},
	setComponent(state, data) {
		state.component = data
	},
	setLoading(state, data) {
		state.loading = data
	},
	setMenubar(state, data) {
		state.menubar = data
	},
	setMenubarIcons(state, data) {
		state.menubarIcons = data
	},
	themes(state, data) {
		state.themes = data
	},
	setComponentsListObj(state, data) {
		state.componentsListObj = {
			...state.componentsListObj,
			...data,
		}
	},
	setCMSCancelToken: (state, data) => {
		const oldData = { ...state.cmsCancelToken }
		state.cmsCancelToken = {
			...oldData, ...data
		}
	}

}

export const getters = {
	componentsForSequence: (state) => (platform) => {
		const data = JSON.parse(JSON.stringify(state.componentContents))
		// filter is sequentially
		const dataFiltered = data.filter((el) => el.is_sequenceable)
		// filter platform, change key and sort sequence
		const filterPlatform = dataFiltered.filter((el) => {
			if (el.platform.includes(platform)) {
				return true
			}
			return false
		})
			.map((el) => {
				return {
					_id: el._id,
					component_code: el.component_code,
					sequence:
						platform === 'desktop'
							? el.sequence_desktop
							: el.sequence_mobile,
				}
			}).sort((a, b) => a.sequence - b.sequence)
		return filterPlatform;
	}
}